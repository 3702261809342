import React, { useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
// import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
// import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Checkbox from "@material-ui/core/Checkbox"
// import NativeSelect from "@material-ui/core/NativeSelect"
import Select from "@material-ui/core/Select"
// import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
// import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
// import FilledInput from "@material-ui/core/FilledInput"
import FormHelperText from "@material-ui/core/FormHelperText"
// import Paper from "@material-ui/core/Paper"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
// import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

// import EmailIcon from "@material-ui/icons/Email"
// import PhoneIcon from "@material-ui/icons/Phone"
// import FacebookIcon from "@material-ui/icons/Facebook"
// import SvgIcon from "@material-ui/core/SvgIcon"
// import { FormGroup } from "@material-ui/core"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

export default function RoomForm() {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const inputLabel = React.useRef(null)

  const minRoomCount = Math.ceil(state.headcount / 4)
  const maxRoomCount = Math.ceil(state.headcount / 2)
  // console.log("min: " + minRoomCount, " MAX: ", maxRoomCount)

  useEffect(() => {
    if (state.roomcount === -99) {
      dispatch({ type: "UPDATE_ROOMCOUNT", value: minRoomCount })
    }
  }, [])

  const useStyles = makeStyles({
    table: {
      minWidth: 450,
    },
  })
  const classes = useStyles()

  const headPerRoom = (index) => {
    var count = 0
    var staging = 0
    for (var i = 0; i <= index; i++) {
      count = Math.floor((state.headcount - staging) / (state.roomcount - i))
      // console.log("i: ", i, " count: ", count)
      staging += count
    }
    // console.log("count: " + count)
    // console.log("staging: ", staging)
    // console.log("index: " + index)
    const names = [...Array(count)].map((_, i) => {
      var n = state.guests[staging - count + i].zhName
      return n
    })
    return "(" + count + "人): " + names
  }

  const handleRoomCount = () => (event) => {
    dispatch({ type: "UPDATE_ROOMCOUNT", value: Number(event.target.value) })
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth={true}>
            <InputLabel ref={inputLabel} htmlFor="headcount">
              房間數
            </InputLabel>
            <Select
              required
              native
              id="roomcount"
              name="roomcount"
              variant="filled"
              defaultValue={state.roomcount}
              onChange={handleRoomCount()}
            >
              {[...Array(maxRoomCount - minRoomCount + 1)].map((_, i) => (
                <option key={i} value={minRoomCount + i}>
                  需要{minRoomCount + i}間房
                </option>
              ))}
              {state.headcount === 1 ? (
                <option key={"single"} value={-1}>
                  我一個人報名，想請菲哥幫忙找其他同性單人團友拼房
                </option>
              ) : (
                ""
              )}
              <option
                key={"NA"}
                value={0}
                // selected={state.roomcount === 0 ? true : false}
              >
                不需要代訂，會自己和Nova訂房
              </option>
            </Select>
            <FormHelperText component="span" id="component-helper-text">
              {state.roomcount <= 1 ? (
                <div />
              ) : (
                <div>
                  {/* <Typography component="span"> */}
                  房間分配預覽，僅供參考，團友入住時可自行調整:
                  {/* </Typography> */}
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {[...Array(Number(state.roomcount))].map((_, r) => (
                          <TableRow key={r}>
                            <TableCell component="th" scope="row">
                              房間{r + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {headPerRoom(r)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        {state.headcount === 1 ? (
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              variant="caption"
              paragraph={true}
            >
              *單人團友如果願意和他人分享房間，請在房間數下拉選項註明
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Typography color="error">需要資料: 房間選項解釋地方在這裡</Typography>
      </Grid>
    </React.Fragment>
  )
}
