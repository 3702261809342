// import React from "react"
import React, { useEffect, useContext } from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
// import TextField from "@material-ui/core/TextField"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Checkbox from "@material-ui/core/Checkbox"
// import Stripe from "components/Stripe/Stripe"
import { makeStyles } from "@material-ui/core/styles"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  button: {
    marginLeft: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tourFee: {
    background: "lightgray",
  },
  alignRight: {
    marginLeft: "auto",
    // background: "lightgray",
  },
})

export default function PaymentForm() {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const classes = useStyles()

  const handleChange = () => (event) => {
    dispatch({ type: "CONSCENT", value: event.target.checked })
  }

  let extracount = 0
  if (state.headcount > 2 && state.roomcount > 0) {
    //extracount should always be positive
    extracount =
      state.headcount - state.roomcount * 2 > 0
        ? state.headcount - state.roomcount * 2
        : 0
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        費用明細
      </Typography>
      {state.waitlist ? (
        <>
          <Typography variant="h6" color="error" gutterBottom>
            (候補無需繳費，費用僅供參考)
          </Typography>
        </>
      ) : (
        ""
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card className={classes.root} raised={true} display="block">
            <CardContent className={classes.tourFee}>
              <Typography component="h2">團費</Typography>
              <Typography className={classes.title} color="textSecondary">
                {state.selectedGroup.groupName}團 - $
                {state.selectedGroup.groupFee} x {state.headcount}人
              </Typography>
            </CardContent>
            <CardActions className={classes.tourFee}>
              <Typography className={classes.button} color="textPrimary">
                現在應繳
                <Button className={classes.button} size="large">
                  加幣${state.selectedGroup.groupFee * state.headcount}
                </Button>
              </Typography>
            </CardActions>
            {state.roomcount > 0 ? (
              <>
                <Divider />
                <CardContent>
                  <Typography component="h2" color="textSecondary">
                    旅館費預覽
                  </Typography>
                  <Typography className={classes.title} color="textSecondary">
                    標準房間 - $209 x {state.roomcount}間 x 4晚 = $
                    {209 * state.roomcount * 4}
                  </Typography>
                  {extracount > 0 ? (
                    <Typography className={classes.title} color="textSecondary">
                      額外旅客 (房間超過兩人) - $15 x {extracount}人 x 4晚 = $
                      {15 * extracount * 4}
                    </Typography>
                  ) : (
                    ""
                  )}
                </CardContent>
                <CardActions>
                  <Typography className={classes.button} color="textSecondary">
                    出團60天前應繳
                  </Typography>
                  <Button className={classes.button} size="small">
                    加幣${(209 * state.roomcount + 15 * extracount) * 4}
                  </Button>
                </CardActions>
                {/* </>
            ) : (
              ""
            )} */}
                <Divider />
                <CardActions className={classes.alignRight}>
                  <Typography
                    className={classes.alignRight}
                    color="textSecondary"
                    component="h2"
                  >
                    總額預覽
                  </Typography>
                  <Button className={classes.button} size="small">
                    加幣$
                    {state.selectedGroup.groupFee * state.headcount +
                      209 * (state.roomcount > 0 ? state.roomcount * 4 : 0) +
                      15 * extracount * 4}
                  </Button>
                </CardActions>
              </>
            ) : (
              ""
            )}
          </Card>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card className={classes.root} raised={false} variant="outlined">
            <CardContent>
              <Typography component="h2">報名須知</Typography>
              <br />
              <Typography variant="body2" gutterBottom display="block">
                請務必詳閱該產品之訂購須知及相關約定。如訂購人非旅客本人，訂購人將居於代理人地位，亦即有義務充分告知旅客本人各行程訂購須知與限制約定，在點選同意後其法律效力及於旅客本人，本公司不接受事後旅客本人以不知或未瞭解等事由做為抗辯理由。
                針對因可歸責於旅客事由（如遲到等）所導致無法成行之損失，本公司恕不負責，旅遊費用亦不予退還。
                <br />
                <br />
                1. 取消/更改條款:
                <br />- 出團前121天以上： 團費退還, 扣除加幣$50手續費。
                <br />- 出團120天~46天以內： 團費退還, 扣除加幣$300手續費。
                <br />- 出團 45天以內： 團費恕不退還。
                <br />
                <br />
                2.{" "}
                <a
                  href="https://www.ynltour.com/fee/fee_detail"
                  target="_blank"
                >
                  費用包含 / 不包含項目
                </a>
                <br />
                <br />
                3. <b>聲明書</b> - 內容以<i>英文同意書</i>
                為主，附上中文版僅供參考：
                <a
                  href="https://drive.google.com/file/d/1ZHbGwlbVJ2TxvaCdyv3NKByhWHdIvUmz/view"
                  target="_blank"
                >
                  英文同意書
                </a>{" "}
                /{" "}
                <a
                  href="https://drive.google.com/file/d/1nvF7wZgZkeNEQXXBKYIZo7MtGgB7A0Ri/view"
                  target="_blank"
                >
                  中文同意書
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="iAgree"
                name="iAgree"
                color="primary"
                checked={state.conscent}
                onChange={handleChange()}
              />
            }
            label="我已詳讀並同意聲明書內容，並接受所有報名須知才能完成報名"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
