import React, { useContext } from "react"
import withLocation from "components/Checkout/Callback.js"

import { navigate } from "gatsby"
import Checkout from "components/Checkout/Checkout.js"
// import {
//   GlobalDispatchContext,
//   GlobalStateContext,
// } from "context/GlobalContextProvider"
const CheckoutPage = ({ search }) => {
  //   const state = useContext(GlobalStateContext)
  //   const dispatch = useContext(GlobalDispatchContext)
  //   console.log("STATE: ", state)
  //get params from URL
  const { callback, success, gid, head, room } = search

  console.log("SEARCH: " + JSON.stringify(search))
  if (callback === "true") {
    if (success === "true") {
      navigate("/success")
      return <></>
    }

    navigate("/")
    return <></>
    // dispatch({
    //   type: "CALLBACK",
    //   //   name: groupName,
    //   gid: gid,
    //   headcount: Number(head) || 0,
    //   roomcount: Number(room) || -99,
    //   activeStep: 2,
    // })
  }
  return <Checkout />
}

export default withLocation(CheckoutPage)
