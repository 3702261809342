import React, { useContext } from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

// import {
//   GlobalDispatchContext,
//   GlobalStateContext,
// } from "context/GlobalContextProvider"

const withLocation = (ComponentToWrap) => (props) => {
  //   const state = useContext(GlobalStateContext)
  //   console.log("LOCATION: ", state)
  return (
    <Location>
      {({ location, navigate }) => (
        <ComponentToWrap
          {...props}
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        />
      )}
    </Location>
  )
}

export default withLocation
