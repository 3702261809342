import React, { useContext } from "react"
// import { useState } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import AccountCircle from "@material-ui/icons/AccountCircle"

import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"
import FacebookIcon from "@material-ui/icons/Facebook"
import SvgIcon from "@material-ui/core/SvgIcon"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

export default function AddressForm() {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const errors = state.checkoutErrors
  const handleChange = (input) => (event) => {
    let newValue

    //handle text field and checkbox differences
    if (
      event.target.value !== "" &&
      typeof event.target.value !== "undefined"
    ) {
      newValue = event.target.value
    } else {
      newValue = event.target.checked
    }

    switch (input) {
      case "headcount":
        dispatch({ type: "UPDATE_HEADCOUNT", value: newValue })
        break
      default:
        dispatch({
          type: "UPDATE_GUEST",
          index: input.index,
          field: input.name,
          value: newValue,
        })
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            id={"headcount"}
            name="headcount"
            label="參加人數"
            fullWidth={true}
            variant="outlined"
            value={state.headcount || 0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            onChange={handleChange("headcount")}
          >
            <MenuItem disabled>參加人數</MenuItem>
            {[...Array(state.maxUsers)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </TextField>
          {state.waitlist ? (
            <FormHelperText error id="component-helper-text">
              報名人數超過空位數，我們會幫忙全部的人安排候補位置，只要一有空位，將會第一時間通知。候補無需繳費。
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>

      {[...Array(Number(state.headcount))].map((_, i) => (
        <Hidden key={i} xsUp={state.headcount === "0"}>
          <br />
          <hr />
          <Typography variant="h6" gutterBottom>
            {state.waitlist ? "候補" : ""}團友{i + 1}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={"zhName_" + i}
                name="name"
                label="中文姓名"
                fullWidth={true}
                autoComplete="name"
                variant="filled"
                defaultValue={(state.guests[i] && state.guests[i].zhName) || ""}
                onBlur={handleChange({ name: "zhName", index: i })}
                error={errors[i] && errors[i].zhName ? true : false}
                helperText={
                  errors[i] && errors[i].zhName ? errors[i].zhName : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={"enName_" + i}
                name="name"
                label="英文姓名 (以護照為準)"
                fullWidth={true}
                autoComplete="name"
                variant="filled"
                defaultValue={(state.guests[i] && state.guests[i].enName) || ""}
                onBlur={handleChange({ name: "enName", index: i })}
                error={errors[i] && errors[i].enName ? true : false}
                helperText={
                  errors[i] && errors[i].enName ? errors[i].enName : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={"email_" + i}
                name="email"
                label="聯絡E-mail"
                fullWidth={true}
                autoComplete="email"
                variant="filled"
                defaultValue={(state.guests[i] && state.guests[i].email) || ""}
                onBlur={handleChange({ name: "email", index: i })}
                error={errors[i] && errors[i].email ? true : false}
                helperText={errors[i] && errors[i].email ? errors[i].email : ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="filled" fullWidth={true} required>
                <TextField
                  required
                  id={"mobile_" + i}
                  name="tel"
                  label="手機號碼"
                  fullWidth={true}
                  autoComplete="tel"
                  variant="filled"
                  defaultValue={
                    (state.guests[i] && state.guests[i].mobile) || ""
                  }
                  onBlur={handleChange({ name: "mobile", index: i })}
                  error={errors[i] && errors[i].mobile ? true : false}
                  helperText={
                    errors[i] && errors[i].mobile ? errors[i].mobile : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText id="component-helper-text">
                  台灣以外的地區請加國碼，例: 美國+1
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                id={"gender_" + i}
                name="sex"
                label="性別"
                fullWidth={true}
                autoComplete="sex"
                variant="filled"
                value={(state.guests[i] && state.guests[i].gender) || ""}
                onBlur={handleChange({ name: "gender", index: i })}
              >
                <MenuItem disabled>性別</MenuItem>
                <MenuItem value="女">女</MenuItem>
                <MenuItem value="男">男</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                id={"ageGroup_" + i}
                name="ageGroup"
                label="大約年齡"
                fullWidth={true}
                autoComplete="ageGroup"
                variant="filled"
                value={(state.guests[i] && state.guests[i].ageGroup) || ""}
                onBlur={handleChange({ name: "ageGroup", index: i })}
              >
                <MenuItem disabled>大約年齡</MenuItem>
                <MenuItem value="6-25">6-25</MenuItem>
                <MenuItem value="26-35">26-35</MenuItem>
                <MenuItem value="36-45">36-45</MenuItem>
                <MenuItem value="46-55">46-55</MenuItem>
                <MenuItem value=">55">>55</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                id={"nationality_" + i}
                name="country"
                label="國籍"
                fullWidth={true}
                autoComplete="country"
                variant="filled"
                value={(state.guests[i] && state.guests[i].nationality) || ""}
                onBlur={handleChange({ name: "nationality", index: i })}
              >
                <MenuItem disabled>國籍</MenuItem>
                <MenuItem value="台灣">台灣</MenuItem>
                <MenuItem value="香港">香港</MenuItem>
                <MenuItem value="澳門">澳門</MenuItem>
                <MenuItem value="中國">中國</MenuItem>
                <MenuItem value="美國/加拿大">>美國/加拿大</MenuItem>
                <MenuItem value="其他">其他</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                select
                id={"origin_" + i}
                name="country"
                label="原出發地"
                fullWidth={true}
                autoComplete="country"
                variant="filled"
                value={(state.guests[i] && state.guests[i].origin) || ""}
                onBlur={handleChange({ name: "origin", index: i })}
              >
                <MenuItem disabled>原出發地</MenuItem>
                <MenuItem value="台灣">台灣</MenuItem>
                <MenuItem value="香港">香港</MenuItem>
                <MenuItem value="澳門">澳門</MenuItem>
                <MenuItem value="多倫多">多倫多</MenuItem>
                <MenuItem value="溫哥華">溫哥華</MenuItem>
                <MenuItem value="美國">美國</MenuItem>
                <MenuItem value="其他">其他</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id={"fb_" + i}
                name="fb"
                label="臉書Facebook顯示名"
                fullWidth={true}
                variant="filled"
                value={(state.guests[i] && state.guests[i].fb) || ""}
                onBlur={handleChange({ name: "fb", index: i })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText id="component-helper-text">
                私人群組審核使用
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id={"line_" + i}
                name="line"
                label="Line ID"
                fullWidth={true}
                variant="filled"
                value={(state.guests[i] && state.guests[i].line) || ""}
                onBlur={handleChange({ name: "line", index: i })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z" />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText id="component-helper-text">
                私人群組審核使用
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormLabel>飲食習慣</FormLabel>
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        variant="filled"
                        id="foodNoBeef"
                        name="foodNoBeef"
                        checked={
                          (state.guests[i] && state.guests[i].foodNoBeef) ||
                          false
                        }
                        onChange={handleChange({
                          name: "foodNoBeef",
                          index: i,
                        })}
                        color="primary"
                      />
                    }
                    label="不吃牛肉"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="foodVegOnly"
                        name="foodVegOnly"
                        checked={
                          (state.guests[i] && state.guests[i].foodVegOnly) ||
                          false
                        }
                        onChange={handleChange({
                          name: "foodVegOnly",
                          index: i,
                        })}
                        color="primary"
                      />
                    }
                    label="不吃肉/素食"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <FormLabel>出租</FormLabel>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="winterGear"
                        name="winterGear"
                        checked={
                          (state.guests[i] && state.guests[i].winterGear) ||
                          false
                        }
                        onBlur={handleChange({
                          name: "winterGear",
                          index: i,
                        })}
                        color="primary"
                      />
                    }
                    label="寒冬裝備($40/天): 外套，雪鞋，雪褲"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="tripodRental"
                        name="tripodRental"
                        checked={
                          (state.guests[i] && state.guests[i].tripodRental) ||
                          false
                        }
                        onBlur={handleChange({
                          name: "tripodRental",
                          index: i,
                        })}
                        color="primary"
                      />
                    }
                    label="腳架 (夜間攝影必備)"
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Hidden>
      ))}
    </React.Fragment>
  )
}
