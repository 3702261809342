import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CustomerForm from "./CustomerForm"
import RoomForm from "./RoomForm"
import PaymentForm from "./PaymentForm"

import Layout from "components/layout"
import SEO from "components/seo"
import Home from "pages/index.js"
import useCheckout from "components/Checkout/useCheckout"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

const steps = ["人數", "房間", "付款", "完成"]

function getStepContent(step) {
  switch (step) {
    case 0:
      return <CustomerForm />
    case 1:
      return <RoomForm />
    case 2:
      return <PaymentForm />
    default:
      throw new Error("Unknown step")
  }
}

// export default function Checkout(props) {
export default function Checkout() {
  // export default function Checkout() {
  //   console.log("Checkout LOCATION: ", props)
  const classes = useStyles()

  const { state, dispatch, handleNext } = useCheckout(steps)
  console.log("Checkout LOCATION: ", state)

  if (
    Object.keys(state.groups).length === 0 ||
    !state.selectedGroup ||
    Object.keys(state.selectedGroup).length === 0
  ) {
    return <Home />
  }
  //   console.log("Checkout state: ", state)

  const activeStep = state.activeStep
  const waitlist = state.waitlist

  //   console.log("STEP: ", activeStep)
  const handleBack = () => {
    dispatch({ type: "PREV_STEP" })
    if (state.selectedGroup.groupType === "blachford") {
      dispatch({ type: "PREV_STEP" })
    }
  }

  const handleCancel = () => {
    dispatch({ type: "RESET" })
  }

  const output = (
    <Layout>
      <SEO title="5天4夜團" />
      <main className={classes.container}>
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textSecondary"
          >
            {state.groupDisplay}
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            // orientation="vertical"
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length - 1 ? (
              <React.Fragment>
                {waitlist ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      候補成功
                    </Typography>
                    <Typography variant="subtitle1">
                      只要有空位，我們將會第一時間以Email通知
                      <br />
                      <br />
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      有什麼問題也歡迎隨時寫信發問: info@ynltour.com
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      恭喜報名成功
                    </Typography>
                    <Typography variant="subtitle1"></Typography>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {Object.keys(state.checkoutErrors).length > 0 ? (
                  <Typography variant="subtitle1" color="error" align="right">
                    資料有誤，請填寫/更正紅色欄位再去下一步。
                  </Typography>
                ) : (
                  ""
                )}
                <div className={classes.buttons}>
                  {activeStep == 0 && (
                    <Button
                      onClick={handleCancel}
                      className={classes.button}
                      variant="outlined"
                    >
                      取消
                    </Button>
                  )}
                  {activeStep !== 0 && (
                    <Button
                      onClick={handleBack}
                      className={classes.button}
                      variant="outlined"
                    >
                      上一頁
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={
                      (state.activeStep === 0 && Number(state.headcount) === 0
                        ? true
                        : false) ||
                      (state.activeStep === 2 && state.conscent === false
                        ? true
                        : false)
                    }
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "下一步"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </Layout>
  )

  return output
}
