import { useContext, useState } from "react"
import validate from "./CheckoutValidation"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

const useForm = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const handleNext = (event) => {
    if (event) event.preventDefault()
    const activeStep = state.activeStep
    switch (activeStep) {
      //Enter customer info
      case 0:
        const errors = validate(state)
        dispatch({ type: "UPDATE_ERRORS", errors: errors })
        if (Object.keys(errors).length === 0) {
          dispatch({ type: "NEXT_STEP" })
        }
        if (state.selectedGroup.groupType === "blachford") {
          dispatch({ type: "NEXT_STEP" })
        }
        break

      case 2:
        dispatch({ type: "SUBMIT" })

        if (!state.waitlist) {
          const stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
          const groupSKU = state.selectedGroup.stripeSKU
          const successURI = `&gid=${state.selectedGroup.uniqueID}`
          const cancelURI = `&gid=${state.selectedGroup.uniqueID}&head=${state.headcount}&room=${state.roomcount}`

          //list of guest uniqueIDs
          const tmp = []
          for (let i = 0; i < state.headcount; i++) {
            tmp.push(state.guests[i].uniqueID)
          }

          const ids = tmp.join(",")

          const items = []

          //add group fee
          //=============
          items.push({ sku: groupSKU, quantity: state.headcount })

          //add hotel fee
          //=============
          //   if (state.roomcount > 0) {
          //     items.push({ sku: "sku_GmNJ3lmxBTPYvj", quantity: state.roomcount })
          //   }

          //add extra guest hotel fee
          //=============
          //   const extracount = state.headcount - state.roomcount * 2
          //   if (extracount > 0) {
          //     items.push({ sku: "sku_GmNdC5hboYQvB2", quantity: extracount })
          //   }

          stripe.redirectToCheckout({
            items: items,
            clientReferenceId: ids,
            successUrl: `${window.location.origin}/checkout?callback=true&success=true${successURI}`,
            cancelUrl: `${window.location.origin}/checkout?callback=true${cancelURI}`,
            customerEmail: state.guests[0].email,
            locale: "zh",
          })
        } else {
          //waitlist
          dispatch({ type: "RESET" })
          dispatch({
            type: "CALLBACK",
            // name: state.selectedGroup.groupName,
            gid: state.selectedGroup.uniqueID,
            headcount: 0,
            roomcount: -99,
            waitlist: true,
            activeStep: 3,
          })
        }
        break
      default:
        dispatch({ type: "NEXT_STEP" })
    }
  }

  return {
    handleNext,
    dispatch,
    state,
  }
}

export default useForm
